import { Icon } from "@/core/components/icon/Icon"
import { cn } from "@/utils/helpers"
import { useTranslation } from "react-i18next"
import { ContractStatus } from "../../constants/contractStatus"
import { TIconName } from "@/core/components/icon/IconUtil"

type CellStatusProps = {
  status: ContractStatus
  hasEnded: boolean
}

export const CellStatus = ({ status, hasEnded }: CellStatusProps) => {
  const { t } = useTranslation("common")
  let icon: TIconName = "edit_document"
  let iconColor: string = "text-gray-500"
  let translationKey = "draft"

  if (status === ContractStatus.Pending) {
    icon = "schedule"
    iconColor = "text-orange-500"
    translationKey = "pending"
  }
  if (status === ContractStatus.Signed) {
    icon = "check_circle"
    iconColor = "text-green-500"
    translationKey = "signed"
  }
  if (status === ContractStatus.Rejected) {
    icon = "cancel"
    iconColor = "text-red-400"
    translationKey = "rejected"
  }
  if (status === ContractStatus.NewRequest) {
    icon = "error"
    iconColor = "text-gray-500"
    translationKey = "new_request"
  }
  if (hasEnded) {
    icon = "hourglass_bottom"
    iconColor = "text-red-400"
    translationKey = "ended"
  }

  return (
    <div
      className={cn(
        "flex w-fit items-center justify-center gap-1 rounded-xl px-2 py-0.5",
        status === ContractStatus.Signed && "bg-green-100",
        status === ContractStatus.Pending && "bg-orange-100",
        status === ContractStatus.Rejected && "bg-red-100",
        status === ContractStatus.NewRequest && "bg-gray-100",
        hasEnded && "bg-red-100",
      )}
    >
      <Icon icon={icon} className={cn("text-[16px]", iconColor)} />
      <p className="text-sm text-gray-600">{t(translationKey)}</p>
    </div>
  )
}
